.header-bg {
   background-image: url("assets/backgrounds/freq-bg.png");
   background-repeat: no-repeat;
   background-size: cover;
   background-position: center center;
   width: 100%;
   height: 100%;
   object-fit: cover;
   object-position: center;
   position: absolute;
   z-index: 1;
}

.select {
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
   background: url(assets/img/icoon-select.svg) right 0 top 50% no-repeat white !important;
   position: relative;
}

.audio_player .rhap_controls-button.forward,
.audio_player .rhap_controls-button.backward {
   display: none;
}

.audio_player .rhap_time.rhap_total-time {
   display: none;
}

.audio_player .rhap_progress-section {
   flex-direction: row-reverse;
}

.volume-control {
   width: 100px;
   opacity: 0;
   transition: opacity 0.3s ease-in-out;
}

.volume-control.hovered {
   opacity: 1;
}

input[type="range"] {
   width: 100%;
}

.rhap_volume-controls {
   justify-content: end;
}

.rhap_main-controls {
   width: calc(100% - 100px);
   padding-left: 50px;
}

.rhap_container {
   padding: 20px 32px;
}

.rhap_play-pause-button svg,
.rhap_volume-button svg {
   color: rgb(134, 25, 143);
}

.rhap_volume-indicator,
.rhap_progress-indicator {
   background: rgb(134, 25, 143) !important;
}

.rhap_volume-filled,
.rhap_progress-filled {
   background: rgba(134, 25, 143, 0.5) !important;
}

.rhap_main.rhap_stacked {
   margin: 16px 20px;
}

/* audio player css */
.audio-player {
   display: flex;
   flex-direction: column;
   align-items: center;
   margin-top: 20px;
}

input[type="range"] {
   width: 80%;
   margin: 10px;
}

/* Animation */
.whole {
   display: inline-flex;
   justify-content: center;
   align-items: center;
}

.play {
   width: 0;
   height: 0;
   border-bottom: 15px solid transparent;
   border-right: 3px solid transparent;
   border-top: 15px solid transparent;
   border-left: 20.5px solid rgba(250, 210, 253);
   margin-left: 1.5em;
   cursor: pointer;
   opacity: 1;
}

.play.active {
   opacity: 0;
}

.pause {
   width: 17px;
   height: 25px;
   z-index: -1;
   background: transparent;
   transform: translateX(-25px);
   border-left: 4px solid rgba(250, 210, 253);
   border-right: 4px solid rgba(250, 210, 253);
   cursor: pointer;
   opacity: 0;
}

.pause.active {
   opacity: 1;
}

.soundWaves {
   position: relative;
   height: 40px;
   background: transparent;
   display: inline-flex;
   justify-content: space-between;
   margin-right: 1em;
   z-index: 3;
   transform: rotate(180deg);
   margin: 20px;
}

.line1,
.line2,
.line3,
.line4,
.line5 {
   background: linear-gradient(
      123deg,
      rgba(250, 210, 253) 55%,
      transparent 10%,
      #ffffff 70%
   );
   background-size: 700%;
   height: 40px;
   min-width: 6px;
   animation: upDown 1s linear infinite, colourAnimate 4s linear infinite;
   animation-delay: calc(var(--i) * -0.25s);
   transition: all 1.5s;
   opacity: 1;
   margin: 2px;
   animation-play-state: paused;
}

.line1.active,
.line2.active,
.line3.active,
.line4.active,
.line5.active {
   opacity: 1;
   animation: upDown 1s linear infinite, colourAnimate 4s linear infinite;
   animation-delay: calc(var(--i) * -0.25s);
   margin: 2px;
   animation-play-state: revert;
}

@keyframes colourAnimate {
   0% {
      background-position: 0 0;
   }

   100% {
      background-position: 700% 0;
   }
}

@keyframes upDown {
   0% {
      height: 10px;
   }

   50% {
      height: 40px;
   }

   100% {
      height: 10px;
   }
}

.circle {
   min-width: 50px;
   height: 50px;
   border-radius: 50%;
   background: linear-gradient(210deg, #ff74e1, #fb91ff);
   transform: translate(80px, -40px);
   animation: move 12s linear infinite;
   animation-play-state: paused;
   margin: 0;
}

@keyframes move {
   0% {
      transform: translate(80px, -40px);
   }

   15% {
      transform: translate(80px, 40px);
   }

   45% {
      transform: translate(420px, 40px);
   }

   60% {
      transform: translate(420px, -40px);
   }

   100% {
      transform: translate(80px, -40px);
   }
}

.circle2 {
   min-width: 50px;
   height: 50px;
   border-radius: 50%;
   background: linear-gradient(210deg, #ffb545, #ffc391);
   transform: translate(380px, 40px);
   animation: move2 12s linear infinite;
   animation-play-state: paused;
}

@keyframes move2 {
   0% {
      transform: translate(380px, 40px);
   }

   15% {
      transform: translate(380px, -40px);
   }

   45% {
      transform: translate(20px, -40px);
   }

   60% {
      transform: translate(20px, 40px);
   }

   100% {
      transform: translate(380px, 40px);
   }
}

.circle.active,
.circle2.active {
   animation-play-state: running;
}

.audio-container {
   position: relative;
   width: 100%;
   justify-content: center;
   align-items: center;
   display: flex;
   flex-direction: column;
   border-radius: 20px;
   background: rgba(250, 210, 253, 0.5);
   backdrop-filter: blur(6px);
}

.audio-container input[type="range"] {
   accent-color: rgba(250, 210, 253);
}

.audio-text {
   margin: 20px;
   min-width: 152px;
}

.volume-container {
   position: relative;
   display: flex;
   margin-right: auto;
   margin-left: 40px;
}

.stroke-solid {
   stroke-dashoffset: 0;
   stroke-dasharray: 300;
   stroke-width: 4px;
   transition: stroke-dashoffset 1s ease, opacity 1s ease;
}

.icon {
   transform: scale(0.8);
   transform-origin: 50% 50%;
   transition: transform 200ms ease-out;
}

#play:hover {
   .stroke-solid {
      opacity: 1;
      stroke-dashoffset: 300;
      stroke: #2dd4bf;
   }

   .icon {
      transform: scale(0.9);
   }
}

svg#play {
   width: 35px;
   display: block;
   cursor: pointer;
   transform-origin: 50% 50%;
}

.table-gradient {
   background: radial-gradient(
      circle,
      rgba(232, 121, 249, 1) 2%,
      rgba(30, 30, 30, 1) 85%
   );
}

.custom-shape-divider-top-1709652370 {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   overflow: hidden;
   line-height: 0;
   transform: rotate(180deg);
}

.custom-shape-divider-top-1709652370 svg {
   position: relative;
   display: block;
   width: calc(224% + 1.3px);
   height: 333px;
}

.custom-shape-divider-top-1709652370 .shape-fill {
   fill: #1e1e1e;
}

.custom-shape-divider-bottom-1709652370 svg {
   position: relative;
   display: block;
   width: calc(224% + 1.3px);
   height: 333px;
}

.custom-shape-divider-bottom-1709652370 .shape-fill {
   fill: #1e1e1e;
}

.custom-shape-divider-bottom-1709652370 {
   position: absolute;
   bottom: 0;
   left: 0;
   width: 100%;
   overflow: hidden;
   line-height: 0;
}

.scroll-design::-webkit-scrollbar-track {
   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
   background-color: #f5f5f5;
}

.scroll-design::-webkit-scrollbar {
   width: 10px;
   background-color: #f5f5f5;
}

.scroll-design::-webkit-scrollbar-thumb {
   border-radius: 10px;
   background-image: -webkit-gradient(
      linear,
      left bottom,
      left top,
      color-stop(0.44, #2b0b3d),
      color-stop(0.72, #1e1e1e),
      color-stop(0.86, #2b0b3d)
   );
}

.custom-shape-divider-left-1709652370 svg {
   position: relative;
   display: block;
   width: calc(224% + 1.3px);
   height: 333px;
}

.custom-shape-divider-left-1709652370 .shape-fill {
   fill: #1e1e1e;
}

.custom-shape-divider-left-1709652370 {
   width: 100%;
   transform: rotate(180deg);
   overflow: hidden;
   line-height: 0;
}
html,
body,
#root {
   height: 100%;
}
